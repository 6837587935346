import React from "react";
import {useNavigate} from "react-router-dom";

const SubNavBar = () => {
    return <div>

    </div>
}

type AccountSubNavProps = {
    onAccountHover: boolean

    setOnAccountHover: React.Dispatch<React.SetStateAction<boolean>>
}

const AccountSubNav = ({setOnAccountHover, onAccountHover}: AccountSubNavProps) => {
    const navigate = useNavigate();

    return <div onMouseEnter={() => {
        setOnAccountHover(true);
    }} onMouseLeave={() => {
        setOnAccountHover(false);
    }} className={`absolute bg-white shadow-xl rounded-xl bg-gray-100  w-56 flex flex-col gap-5 top-48 transition-all duration-1000 ${onAccountHover ? 'left-36 z-10' : '-left-52'}`}>
        <div className="bg-white transition-all duration-200 hover:bg-gray-400 active:bg-gray-800  px-5 py-3">
            <button onClick={() => {
                navigate('/cs-account');
                window.location.reload();
            }}>Tài khoản CS</button>
        </div>
        <div className="bg-white transition-all duration-200 hover:bg-gray-400 active:bg-gray-800  px-5 py-3">
            <button onClick={() => {
                navigate('/admin/teacher');
                window.location.reload();
            }}>Giáo Viên</button>
        </div>
        <div className="bg-white transition-all duration-200 hover:bg-gray-400 active:bg-gray-800  px-5 py-3">
            <button onClick={() => {
                navigate('/admin/people');
                window.location.reload();
            }}>Học Sinh</button>
        </div>
    </div>
}

export {AccountSubNav};
