import React, {useEffect, useState} from "react";
import PageNumber from "./PageNumber";
import {PaginationProps} from "../../typing/ui/pagination/pagination";




const Pagination = ({total, className, selectedPage, setSelectedPage}: PaginationProps) => {
    const pageBefore = 1;
    const maxPage = 5;
    const [pages, setPages] = useState<number[]>([]);

    useEffect(() => {
        let myArray = Array.from({ length: total }, (_, index) => index + 1);
        setPages(myArray);
    }, [total]);

    useEffect(() => {
        console.log("Selected change: " + selectedPage);
    }, [selectedPage])

    return <div className={`${className} flex gap-3 items-center bg-gray-200 w-fit px-8 py-2 rounded-2xl`}>
        <button onClick={() => {
            if (selectedPage > 1) {
                setSelectedPage(selectedPage - 1);
            }
        }}>
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                <path strokeLinecap="round" strokeLinejoin="round" d="M18.75 19.5l-7.5-7.5 7.5-7.5m-6 15L5.25 12l7.5-7.5" />
            </svg>
        </button>
        {pages.map((page, index) => {
            if (total > maxPage) {
                if ((page < 3) && (selectedPage < 3 - 1)) {
                    return <PageNumber page={page} selectedPage={selectedPage} setSelectedPage={setSelectedPage}/>
                }else {
                    if ((page >= selectedPage - pageBefore) && (page < selectedPage + 4)) {
                        return <PageNumber page={page} selectedPage={selectedPage} setSelectedPage={setSelectedPage}/>
                    }
                }
            }else {
                return <PageNumber selectedPage={selectedPage} page={page} key={page} setSelectedPage={setSelectedPage}/>
            }
        })}
        {(selectedPage < total - 3) ? <div>...</div> : null }
        <button onClick={() => {
            if (selectedPage < total) {
                setSelectedPage(selectedPage + 1);
            }
        }}>
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                <path strokeLinecap="round" strokeLinejoin="round" d="M11.25 4.5l7.5 7.5-7.5 7.5m-6-15l7.5 7.5-7.5 7.5" />
            </svg>
        </button>
    </div>
}
export default Pagination;
