import React from "react";
import {PageNumberProps} from "../../typing/ui/pagination/pagination";





const PageNumber = ({page, selectedPage, setSelectedPage}: PageNumberProps) => {
    return <div onClick={() => {
        setSelectedPage(page);
    }} className={`${(selectedPage === page) ? 'border-b-4 border-b-red-300' : 'hover:border-b-4 hover:border-blue-300'} transition-all duration-300  px-4 py-1`}>
        <label>{page}</label>
    </div>
}

export default PageNumber;
