import React, {useContext, useEffect, useState} from "react";
import axios from "axios";
import CourseCard from "../../components/card/CourseCard";
import CourseRegistrationForm from "../../components/form/CourseRegistrationForm";
import {SystemContext, SystemContextProps} from "../../App";
import {CourseProps} from "../../typing/course/course";



const AdminCoursePage = () => {
    const [arr, setArr] = useState<CourseProps[]>([])
    const {isPhone, isLogin} = useContext(SystemContext) as SystemContextProps;
    const [isAddCourse, setIsAddCourse] = useState(false);
    const [isUpdate, setIsUpdate] = useState(false);
    const [onCourseLoading, setOnCourseLoading] = useState(true);
    const [course, setCourse] = useState<CourseProps>({
        "id": 0,
        "name": "",
        "classCode": "",
        "dateOfWeek": 0,
        "startDate": "",
        "teacherId": 0,
        "teacherName": "",
        "teachingLevel": "",
        "startTime": "12:30",
        "duration": 0,
        "totalSession": 0,
        "classLessons": [],
        "studentIds": [],
        "taId": 0,
        "taName": '',
    })


    useEffect(() => {
        localStorage.ocean_education_current_course_selected = "0";
        axios.get(`${process.env.REACT_APP_API_URL}api/class-type`, {
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + localStorage.ocean_education_token,
            }
        }).then(res => {
            setArr(res.data);
            setOnCourseLoading(false);
            console.log(res.data);
        }).catch(err => {
            console.log(err);
        });
    }, []);



    return <div className={``}>
        <div className={`pl-14 pt-10 w-11/12 ${!isPhone ? 'fixed left-28' : ''} ${isAddCourse ? 'opacity-20' : 'opacity-100'}  overflow-auto h-screen`}>
            <div className=" border-b-2 border-b-gray-300 w-full flex justify-between mr-5 pb-3">
                <div>
                    <label className="text-4xl text-green-600">Khoá Học</label>
                </div>
                <div>
                    <div onClick={() => {
                        setIsUpdate(false)
                        setIsAddCourse(true);
                        setCourse({
                            "id": 0,
                            "name": "",
                            "classCode": "",
                            "dateOfWeek": 0,
                            "startDate": "",
                            "teacherId": 0,
                            "teacherName": "",
                            "teachingLevel": "",
                            "startTime": "12:30",
                            "duration": 0,
                            "totalSession": 0,
                            "classLessons": [],
                            "studentIds": [],
                            "taId": 0,
                            "taName": '',
                        })
                    }} className="transition-colors duration-200 cursor-pointer bg-purple-300 p-2 rounded-xl hover:bg-gray-200 active:bg-gray-600">
                        <label>Thêm Khoá Học</label>
                    </div>
                </div>
            </div>
            <div className="mt-10 flex gap-6 flex-wrap mb-16">
                {arr.map((e,index) => <CourseCard course={e} key={index} isEnterprise={true} setIsAddCourse={setIsAddCourse} setCourse={setCourse} setIsUpdate={setIsUpdate} />)}
            </div>
            {onCourseLoading ? <div>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-10 h-10 my-auto mx-auto animate-spin">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99" />
                </svg>
            </div> : null}
        </div>
        {isAddCourse ? <CourseRegistrationForm course={course} setCourse={setCourse} setIsAddCourse={setIsAddCourse} isUpdate={isUpdate} setIsUpdate={setIsUpdate}/> : null}
    </div>
}

export default AdminCoursePage;
