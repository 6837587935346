import React, {useContext, useEffect, useState} from "react";
import axios from "axios";
import {SystemContext, SystemContextProps} from "../../App";

const AnnouncementPage = () => {
    const {isPhone, isLogin} = useContext(SystemContext) as SystemContextProps;
    const [announcement, setAnnouncement] = useState({
        topic: "",
        content: ""
    });
    const [subjectMissing, setSubjectMissing] = useState(false);
    const [contentMissing, setContentMissing] = useState(false);

    const inputHandleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        setAnnouncement((prevInfo) => ({
            ...prevInfo,
            [name]: value
        }));
        setSubjectMissing(false);
        setContentMissing(false);
    };

    const textareaHandleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        const { name, value } = event.target;
        setAnnouncement((prevInfo) => ({
            ...prevInfo,
            [name]: value
        }));
        setSubjectMissing(false);
        setContentMissing(false);
    }

    const announce = () => {
        if ((announcement.topic.length != 0) || (announcement.content.length != 0)) {
            axios.post(`${process.env.REACT_APP_API_URL}api/manager-controller`, announcement, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + localStorage.ocean_education_token,
                }
            }).then(res => {
                alert("Thông báo đã được gửi");
            }).catch(err => {
                console.log(err);
                alert("Đã xảy ra lỗi khi gửi thông báo");
            });
        }else if (!((announcement.topic.length != 0) || (announcement.content.length != 0))) {
            setSubjectMissing(true);
            setContentMissing(true);
        }else if (announcement.topic.length == 0) {
            setSubjectMissing(true);
        }else {
            setContentMissing(true);
        }

    }


    return <div className={`pl-5 pt-10 w-full ${!isPhone ? 'fixed left-28' : ''}  overflow-auto h-screen`}>
        <div className="font-bold text-3xl">
            <label>Thông báo</label>
        </div>
        <div className={`border-2  w-2/3 mt-4 ${subjectMissing ? 'border-red-300' : 'border-gray-300'}`}>
            <input className="w-full h-full  px-4 py-3" onChange={inputHandleChange} value={announcement.topic} name={"topic"} type={"text"} placeholder={"Tựa đề"} style={{outline: 'none'}}/>
        </div>
        <div className={`border-2  w-2/3 mt-4 ${contentMissing ? 'border-red-300' : 'border-gray-300'}`}>
            <textarea className="w-full h-full min-h-[300px] h-fit px-4 py-3" onChange={textareaHandleChange} value={announcement.content} name={"content"} placeholder={"Nội dung thông báo"} style={{outline: 'none'}}/>
        </div>
        <div className="bg-blue-100 w-fit mt-9 rounded-xl hover:bg-gray-50 active:bg-blue-300">
            <button onClick={announce} className="py-2 px-5 ">Thông báo</button>
        </div>
    </div>
}

export default AnnouncementPage;
