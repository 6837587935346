import React, {useContext, useEffect, useState} from "react";
import CourseCard from "../../components/card/CourseCard";
import axios from "axios";
import {CourseProps} from "../../typing/course/course";
import {SystemContext, SystemContextProps} from "../../App";

const TeacherCoursesPage = () => {
    const {isPhone, isLogin} = useContext(SystemContext) as SystemContextProps;
    const [teacherClass, setTeacherClass] = useState([]);
    const [isUpdate, setIsUpdate] = useState(false);
    const [isAddCourse, setIsAddCourse] = useState(false);
    const [course, setCourse] = useState<CourseProps>({
        "id": 0,
        "name": "",
        "classCode": "",
        "dateOfWeek": 0,
        "startDate": "",
        "teacherId": 0,
        "teacherName": "",
        "teachingLevel": "",
        "startTime": "",
        "duration": 0,
        "totalSession": 0,
        "classLessons": [],
        "studentIds": [],
        "taId": 0,
        "taName": '',
    })




    useEffect(() => {
        localStorage.ocean_education_current_course_selected = "0";
        axios.get(`${process.env.REACT_APP_API_URL}api/teacher/class-type/jwt`, {
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + localStorage.ocean_education_token,
            }
        }).then(res => {
            setTeacherClass(res.data);
            console.log(res.data);
        }).catch(err => {
            console.log(err);
        });
    }, [])



    return <div className="flex">
        <div className={`pl-5 pt-10 w-full ${!isPhone ? 'fixed left-28' : ''}  overflow-auto h-screen`}>
            <div className="border-b-2 border-b-gray-300 w-full">
                <label className="text-4xl text-green-600">Khoá Học</label>
            </div>
            <div className="mt-10 flex gap-6 flex-wrap mb-16">
                {teacherClass.map((classType,index) => <CourseCard setCourse={setCourse} setIsAddCourse={setIsAddCourse} setIsUpdate={setIsUpdate}  key={index} course={classType} isEnterprise={false} />)}
            </div>
        </div>
    </div>
}

export default TeacherCoursesPage;
