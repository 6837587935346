import React, {useContext, useEffect, useState} from "react";
import axios from "axios";
import {base64ToFile, downloadFile} from "../../components/utils/utils";
import {LessonProps} from "../../typing/lesson/lesson";
import LessonRegistrationForm from "../../components/form/LessonRegistrationForm";
import {SystemContext, SystemContextProps} from "../../App";
import DeleteConfirmation from "../../components/dialog/DeleteConfirmation";

interface LessonRowBuilderProps {
    lesson: LessonProps,
    idx: number
}

const AdminLessonPage = () => {
    const {isPhone, isLogin} = useContext(SystemContext) as SystemContextProps;
    const [file, setFile] = useState(null);
    const [isAddLesson, setIsAddLesson] = useState(false);
    const [lessons, setLessons] = useState<LessonProps[]>([]);
    const [deletedLesson, setDeletedLesson] = useState<LessonProps>({
        "id": 0,
        "name": '',
        "fileId": '',
        "level": ''
    });
    const [requireDelete, setRequireDelete] = useState(false);
    const [isUpdate, setIsUpdate] = useState(false);
    const [updateLesson, setUpdateLesson] = useState<LessonProps>({
        id: 0,
        name: '',
        fileId: '',
        level: '',
    });
    const [onLessonLoading, setOnLessonLoading] = useState(true);

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_URL}api/lesson`, {
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + localStorage.ocean_education_token,
            }
        }).then(res => {
            setLessons(res.data);
            setOnLessonLoading(false);
        }).catch(err => {
            console.log(err);
        });
    }, [])


    const RowBuilder: React.FC<LessonRowBuilderProps> = ({lesson, idx}) => {
        const [onDownload, setOnDownload] = useState(false);

        const onUpdateClick = () => {
            setUpdateLesson(lesson);
            setIsUpdate(true);
            setIsAddLesson(true);
        }

        const onDownloadClick = () => {
            setOnDownload(true);
            axios.get(`${process.env.REACT_APP_API_URL}api/lesson/get-file?name=${lesson.id}.${lesson.fileId}`, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + localStorage.ocean_education_token,
                }
            }).then(res => {
                setOnDownload(false);
                downloadFile(base64ToFile(res.data, "", ""), `${lesson.name}.${lesson.fileId}`);

            }).catch(err => {
                console.log(err);
            });
        }

        const onDeleteHandle = () => {
            setRequireDelete(true);
            setDeletedLesson(lesson);
        }

        return <tr className="border-b transition duration-300 ease-in-out hover:bg-neutral-100 dark:border-neutral-500 dark:hover:bg-neutral-600">
            <td className="whitespace-nowrap px-6 py-4 font-medium">{idx + 1}</td>
            <td className="whitespace-nowrap px-6 py-4">{lesson.name}</td>
            <td className="whitespace-nowrap px-6 py-4">{lesson.fileId}</td>
            <td className="whitespace-nowrap px-6 py-4">{lesson.level}</td>
            <td className="whitespace-nowrap px-6 py-4">
                <div className="flex gap-4">
                    <div onClick={onUpdateClick} className="w-fit px-3.5 bg-green-200 text-center py-1.5 rounded-xl hover:bg-gray-100 active:bg-green-500 cursor-pointer">
                        CHỈNH SỬA
                    </div>
                    <div onClick={onDownloadClick} className="w-fit px-3.5 bg-blue-200 text-center py-1.5 rounded-xl hover:bg-gray-100 active:bg-blue-400 active:text-white cursor-pointer">
                        {!onDownload ? 'TẢI FILE' : <div>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 my-auto mx-auto animate-spin">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99" />
                            </svg>
                        </div>}
                    </div>
                    <div onClick={onDeleteHandle} className="w-fit px-3.5 bg-red-400 text-center py-1.5 rounded-xl hover:bg-gray-100 active:bg-red-700 active:text-white cursor-pointer">
                        XOÁ
                    </div>
                </div>

            </td>
        </tr>
    }

    return <div className={`pl-14 pt-10 w-11/12 ${!isPhone ? 'fixed left-28' : ''}  overflow-auto h-screen`}>
        <label className={'text-3xl font-bold'}>Bài Hoc</label>
        <div className="mt-2 ">
            <div onClick={() => {
                setIsAddLesson(true)
            }} className="bg-green-200 w-fit p-1.5 rounded-xl hover:bg-green-500 active:bg-green-800 cursor-pointer">
                Thêm bài học
            </div>
        </div>

        <div className="w-full">
            <div className="flex flex-col">
                <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <div className="inline-block min-w-full py-2 sm:px-6 lg:px-8">
                        <div className="overflow-hidden">
                            <table className="min-w-full text-left text-sm font-light">
                                <thead className="border-b font-medium dark:border-neutral-500">
                                <tr>
                                    <th scope="col" className="px-6 py-4">#</th>
                                    <th scope="col" className="px-6 py-4">Tên Bài Học</th>
                                    <th scope="col" className="px-6 py-4">Loại file</th>
                                    <th scope="col" className="px-6 py-4">Công cụ</th>
                                    <th scope="col" className="px-6 py-4"></th>
                                </tr>
                                </thead>
                                <tbody>
                                {lessons.map((lesson, index) => <RowBuilder lesson={lesson} idx={index} key={index} />)}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        {onLessonLoading ? <div>
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 my-auto mx-auto animate-spin">
                <path strokeLinecap="round" strokeLinejoin="round" d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99" />
            </svg>
        </div> : null}
        {isAddLesson ? <LessonRegistrationForm isAddLesson={isAddLesson} setIsAddLesson={setIsAddLesson} isUpdate={isUpdate} setIsUpdate={setIsUpdate} lesson={updateLesson} /> : null}
        {requireDelete ? <DeleteConfirmation lesson={deletedLesson} setRequireDelete={setRequireDelete}/> : null}
    </div>
}

export default AdminLessonPage;
