import React, {useEffect, useState} from "react";
import axios from "axios";
import {LessonRegistrationFormProps, LevelSelectionProps} from "../../typing/lesson/lesson";
import {LevelProps} from "../../typing/lesson/level";







const LessonRegistrationForm: React.FC<LessonRegistrationFormProps> = ({setIsAddLesson, isAddLesson, setIsUpdate, isUpdate, lesson}) => {
    const [isSelectingLevel, setIsSelectingLevel] = useState(false);
    const [lessonId, setLessonId] = useState(isUpdate ? lesson.id : 0);
    const [levels, setLevels] = useState<LevelProps[]>([]);
    const [level, setLevel] = useState(isUpdate ? lesson.level : '');
    const [lessonName, setLessonName] = useState(isUpdate ? lesson.name : '');
    const [lessonFile, setLessonFile] = useState<File | null>(null);
    // @ts-ignore
    const [pdfFile, setPdfFile] = useState<File | null>(null);
    const [onFileLoading, setOnFileLoading] = useState(false);
    const [curriculumLoading, setCurriculumLoading] = useState(true);


    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_URL}api/curriculum`, {
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + localStorage.ocean_education_token,
            }
        }).then(res => {
            const levelData = res.data;
            setLevels(res.data);
            console.log(res.data);
            setCurriculumLoading(false);
        }).catch(err => {
            console.log(err);
        });


    }, [])

    const LevelSelection: React.FC<LevelSelectionProps> = ({setLevel, baseLevel}) => {

        const onSelectHandle = () => {
            setLevel(baseLevel);
            setIsSelectingLevel(false);
        }

        return <div onClick={onSelectHandle}  className="hover:bg-gray-100 px-4 py-2 rounded-b-xl">
            <label>{baseLevel}</label>
        </div>
    }

    function getFileExtension(filename: string) {
        const extensionRegex = /\.([0-9a-z]+)$/i;
        const match = extensionRegex.exec(filename);
        return match ? match[1] : ''; // Return an empty string if there's no match
    }

    const addLessonHandle = () => {
        const fileData = new FormData();
        // @ts-ignore
        fileData.append("file", lessonFile);
        const pdfData = new FormData();
        // @ts-ignore
        pdfData.append("file", pdfFile);
        if (!isUpdate) {
            setOnFileLoading(true);
            axios.post(`${process.env.REACT_APP_API_URL}api/lesson`, {
                name: lessonName,
                // @ts-ignore
                fileExtension: getFileExtension(lessonFile.name),
                curriculum: level
            }, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + localStorage.ocean_education_token,
                }
            }).then(res => {
                // @ts-ignore
                axios.post(`${process.env.REACT_APP_API_URL}api/lesson/${res.data.lesson_id}/${getFileExtension(lessonFile.name)}/lesson/upload`, fileData, {
                    headers: {
                        Authorization: "Bearer " + localStorage.ocean_education_token,
                    }
                }).then(resp => {
                    console.log(resp);
                    // @ts-ignore
                    axios.post(`${process.env.REACT_APP_API_URL}api/lesson/${res.data.lesson_id}/${getFileExtension(pdfFile.name)}/lesson/upload`, pdfData, {
                        headers: {
                            Authorization: "Bearer " + localStorage.ocean_education_token,
                        }
                    }).then(res => {
                        console.log(res);
                        setOnFileLoading(false);
                        setIsAddLesson(false);
                        window.location.reload();
                    }).catch(err => {
                        console.log(err)
                    })
                }).catch(err => {
                    console.log(err)
                })


            }).catch(err => {
                console.log(err);
            });
        }else {
            setOnFileLoading(true);
            axios.put(`${process.env.REACT_APP_API_URL}api/lesson`, {
                id: lessonId,
                name: lessonName,
                fileId: "",
                level: level
            }, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + localStorage.ocean_education_token,
                }
            }).then(res => {
                if (lessonFile !== null) {
                    axios.post(`${process.env.REACT_APP_API_URL}api/lesson/${lessonId}/${getFileExtension(lessonFile.name)}/lesson/upload`, fileData, {
                        headers: {
                            Authorization: "Bearer " + localStorage.ocean_education_token,
                        }
                    }).then(res => {
                        console.log(res);
                        if (pdfFile !== null ) {
                            axios.post(`${process.env.REACT_APP_API_URL}api/lesson/${lessonId}/${getFileExtension(pdfFile.name)}/lesson/upload`, pdfData, {
                                headers: {
                                    Authorization: "Bearer " + localStorage.ocean_education_token,
                                }
                            }).then(res => {
                                setOnFileLoading(false);
                                console.log(res)
                                setIsAddLesson(false);
                                window.location.reload();
                            }).catch(err => {
                                console.log(err)
                            })
                        }
                    }).catch(err => {
                        console.log(err)
                    })
                }


            }).catch(err => {
                console.log(err);
            });
        }
    }

    return <div className="fixed inset-0 flex items-center justify-center opacity-100 ">
        <div className="w-1/2 h-fit overflow-auto bg-gray-50 border-2 border-gray-400 p-2 rounded-xl flex flex-col gap-4 relative pb-10 mt-10 mb-10 ">
            <label className="text-center text-3xl font-bold">Form Tạo Bài Học</label>
            <div className="">
                <div className="w-2/3 h-full bg-gray-100 border-2 border-gray-200">
                    <input onChange={(e) => {
                        setLessonName(e.target.value)
                    }} value={lessonName} type={"text"} className="w-full h-full bg-transparent py-2 px-3" placeholder={"Tên bài học"} style={{outline: 'none'}}/>
                </div>
            </div>
            <div className="flex flex-col gap-3">
                <label className="text-lg font-bold">File trình chiếu</label>
                <input onChange={(e) => {
                    // @ts-ignore
                    setLessonFile(e.target.files[0]);
                }} type={"file"} placeholder="file bai hoc"/>
            </div>
            <div className="flex flex-col gap-3">
                <label className="text-lg font-bold">File PDF</label>
                <input onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    // @ts-ignore
                    setPdfFile(e.target.files[0]);
                }} type={"file"} placeholder="file bai hoc"/>
            </div>
            <div>
                <div className={`flex w-full items-center justify-between pl-5 pr-8`}>
                    <label>Cấp bậc <span className="text-red-600">*</span></label>
                    <div className={`${curriculumLoading ? 'flex' : ''} w-4/5 gap-4  items-center`}>
                        <div onClick={() => {setIsSelectingLevel(true)}} className="border-2 rounded-xl w-1/2">
                            <input onChange={(e) => {}} className="w-full h-full bg-transparent p-2" value={level}  type="text" style={{outline: 'none'}}/>
                        </div>
                        {curriculumLoading ? <div className="flex gap-4 items-center">
                            <div>
                                <label>Đang tải cấp bậc</label>
                            </div>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 my-auto mx-auto animate-spin">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99" />
                            </svg>
                        </div> : null}
                        {isSelectingLevel ? <div className="fixed h-36 overflow-scroll border-2 border-gray-200 bg-white w-fit mt-3 rounded-xl">
                            {levels.map((level, index) => <LevelSelection baseLevel={level.name} setLevel={setLevel}  key={index} />)}
                        </div> : null}
                    </div>
                </div>
            </div>
            <div className="flex justify-between px-4">
                <div className="bg-blue-100 w-fit rounded-xl hover:bg-blue-200 active:bg-blue-400 cursor-pointer">
                    {onFileLoading ? <div className="py-1.5 px-3 flex gap-4">
                        <label>Đang tải file</label>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 my-auto mx-auto animate-spin">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99" />
                        </svg>
                    </div> : <button onClick={addLessonHandle} className="w-full h-full py-1.5 px-3 rounded-xl ">
                        THÊM BÀI HỌC
                    </button>}

                </div>

                <div onClick={() => {
                    setIsAddLesson(false);
                }} className="bg-blue-100 w-fit py-1.5 px-3 rounded-xl hover:bg-blue-200 active:bg-blue-400 cursor-pointer">
                    THOÁT
                </div>
            </div>
        </div>
    </div>
}

export default LessonRegistrationForm;
