import React, {useEffect, useState} from "react";
import axios from "axios";
import {StudentProps} from "../../typing/student/student";
import {CourseProps} from "../../typing/course/course";

interface AttendanceProps {
    students: StudentProps[],
    course: CourseProps
}

interface AttendanceRowProps {
    student: StudentProps,
    idx: number
}

interface AttendanceDateSelectionProps {
    date: string
}

interface SubmitAttendanceProps {
    id: number,
    date: string,
    studentName: string,
    studentEmail: string,
    studentId: number,
    courseId: number,
    status: string
}

const Attendance: React.FC<AttendanceProps> = ({students, course}) => {
    const [attendanceDate, setAttendanceDate] = useState<string[]>([]);
    const [isSelectingDate, setIsSelectingDate] = useState(false);
    const [selectedDate, setSelectedDate] = useState('');
    const [attendances, setAttendances] = useState<SubmitAttendanceProps[]>([]);
    const [submitNew, setSubmitNew] = useState(false);

    useEffect(() => {
        let idx = 0;
        const currentDate = new Date();
        const attendanceArr: string[] = [];
        while (idx < course.totalSession) {
            let startDate = new Date(parseInt(course.startDate.split("-")[2]), parseInt(course.startDate.split("-")[1]) - 1, parseInt(course.startDate.split("-")[0]));
            startDate.setDate(startDate.getDate() + idx * 7);
            console.log(course.startDate.split("-")[2] + "-" + course.startDate.split("-")[1] + "-" + course.startDate.split("-")[0])
            if (startDate > currentDate) {
                break;
            }
            console.log("Append date");
            attendanceArr.push(course.startDate.split("-")[2] + "-" + course.startDate.split("-")[1] + "-" + course.startDate.split("-")[0]);
            idx++;
        }
        setAttendanceDate(attendanceArr);
    }, [])

    useEffect(() => {
        if (selectedDate.length !== 0) {
            axios.get(`${process.env.REACT_APP_API_URL}api/attendance?class=${course.id}&date=${selectedDate}`, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + localStorage.ocean_education_token,
                }
            }).then(res => {
                console.log(res.data);
                if (res.data.length === 0) {
                    initAttendanceList();
                    setSubmitNew(true);
                }else {
                    if (res.data.length === students.length) {
                        setAttendances(res.data);
                    }else {
                        appendMissingStudents(res.data);
                    }
                }
            }).catch(err => {
                console.log(err);
            });
        }
    }, [selectedDate])

    const initAttendanceList = () => {
        let attendanceList: SubmitAttendanceProps[] = [];
        for (let i = 0; i < students.length; i++) {
            attendanceList.push({
                id: 0,
                date: selectedDate,
                studentName: students[i].name,
                studentEmail: students[i].email,
                courseId: course.id,
                status: "NOT_CHECK",
                studentId: students[i].id
            })
        }
        setAttendances(attendanceList);
    }

    const appendMissingStudents = (attendances: SubmitAttendanceProps[]) => {
        const tempAttendance: SubmitAttendanceProps[] = [...attendances];
        for (let i = 0; i < students.length; i++) {
            for (let k = 0; k < tempAttendance.length; k++) {
                if (tempAttendance[k].studentId !== students[i].id) {
                    tempAttendance.push({
                        id: 0,
                        date: selectedDate,
                        studentName: students[i].name,
                        studentEmail: students[i].email,
                        courseId: course.id,
                        status: "NOT_CHECK",
                        studentId: students[i].id
                    });
                    break;
                }
            }
        }
    }

    const onSubmitAttendanceList = () => {
        if (submitNew) {
            axios.post(`${process.env.REACT_APP_API_URL}api/attendance`, attendances, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + localStorage.ocean_education_token,
                }
            }).then(res => {
                alert("Điểm danh thành công")
                console.log(res.data);

            }).catch(err => {
                console.log(err);
            });
        }else {
            // put method
            axios.put(`${process.env.REACT_APP_API_URL}api/attendance?class=${course.id}&date=${selectedDate}`, attendances, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + localStorage.ocean_education_token,
                }
            }).then(res => {
                console.log(res.data);
                alert("Điểm danh thành công");
            }).catch(err => {
                console.log(err);
            });
        }
    }

    useEffect(() => {
        console.log("Attendance change");
        console.log(attendances);
        const copied = [...attendances];
        console.log(copied);
    }, [attendances])

    const AttendanceDateBuilder: React.FC<AttendanceDateSelectionProps> = ({date}) => {


        const onSelectHandle = () => {
            setIsSelectingDate(false);
            setSelectedDate(date);
        }

        return <div onClick={onSelectHandle}  className="hover:bg-gray-100 px-4 py-2 rounded-b-xl">
            <label>{date}</label>
        </div>
    }

    const AttendanceRowBuilder: React.FC<AttendanceRowProps> = ({student, idx}) => {
        const [absence, setAbsence] = useState(false);
        const [inClass, setInClass] = useState(false);

        return <tr className="border-b transition duration-300 ease-in-out hover:bg-neutral-100 dark:border-neutral-500 dark:hover:bg-neutral-600">
            <td className="whitespace-nowrap px-6 py-4 font-medium">{idx + 1}</td>
            <td className="whitespace-nowrap px-6 py-4 font-medium">{student.name}</td>
            <td className="whitespace-nowrap px-6 py-4">{student.email}</td>
            <td className="whitespace-nowrap px-6 py-4">{student.gender}</td>
            <td className="whitespace-nowrap px-6 py-4">{student.dateOfBirth}</td>
            {((selectedDate.length !== 0) && (attendances.length !== 0)) ? <td className="whitespace-nowrap px-6 py-4"><input onChange={() => {
                let tempAttendance = [...attendances];
                tempAttendance[idx] = {
                    ...tempAttendance[idx],
                    status: "ATTEND"
                };
                setAttendances(tempAttendance);
            }} type={"checkbox"} checked={(attendances[idx].status === "ATTEND")}/></td> : null}
            {((selectedDate.length !== 0) && (attendances.length !== 0)) ? <td className="whitespace-nowrap px-6 py-4"><input onChange={() => {
                const tempAttendance: SubmitAttendanceProps[] = [...attendances];
                tempAttendance[idx] = {
                    ...tempAttendance[idx],
                    status: "ABSENCE"
                };
                setAttendances(tempAttendance);
            }} type={"checkbox"} checked={(attendances[idx].status === "ABSENCE")}/></td> : null}
        </tr>
    }

    return <div className="w-full ml-5 mr-5">
        <div className="flex items-center justify-between w-9/12">
            <div>
                <label className="text-3xl font-bold">Danh sách học sinh</label>
            </div>
            {selectedDate.length !== 0 ? <div className="bg-blue-200 rounded-xl hover:bg-gray-50 hover:border-2 hover:border-blue-500 active:bg-blue-500">
                <button onClick={onSubmitAttendanceList} className="px-4 my-2 w-full h-full rounded-xl">Điểm danh</button>
            </div> : null}
        </div>
        <div className="mt-4">
            <div className="text-xl font-bold">
                <label>Ngày</label>
            </div>
            <div className="mt-3 max-w-3xl">
                <div onClick={() => {setIsSelectingDate(true)}} className="border-2 rounded-xl w-1/2">
                    <input onChange={(e) => {}} value={selectedDate} className="w-full h-full bg-transparent p-2"  type="text" style={{outline: 'none'}}/>
                </div>
                {isSelectingDate ? <div className="fixed h-20 max-h-36 overflow-scroll  border-2 border-gray-200 bg-white w-44 mt-3 rounded-xl">
                    {attendanceDate.map((attendance, index) => <AttendanceDateBuilder date={attendance}  key={index} />)}
                </div> : null}
            </div>
        </div>
        <div className="w-11/12">
            <div className="flex flex-col">
                <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <div className="inline-block min-w-full py-2 sm:px-6 lg:px-8">
                        <div className="overflow-hidden">
                            <table className="min-w-full text-left text-sm font-light">
                                <thead className="border-b font-medium dark:border-neutral-500">
                                <tr>
                                    <th scope="col" className="px-6 py-4">#</th>
                                    <th scope="col" className="px-6 py-4">Tên</th>
                                    <th scope="col" className="px-6 py-4">Email</th>
                                    <th scope="col" className="px-6 py-4">Giới tính</th>
                                    <th scope="col" className="px-6 py-4">Ngày Tháng Năm Sinh</th>
                                    <th scope="col" className="px-6 py-4">Có mặt</th>
                                    <th scope="col" className="px-6 py-4">Vắng</th>
                                </tr>
                                </thead>
                                <tbody>
                                {students.map((student, index) => <AttendanceRowBuilder key={index} student={student} idx={index}/>)}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
}

export default Attendance;
