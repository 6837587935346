import React, {useEffect, useState} from "react";
import CurriculumItem from "../card/CurriculumItem";
import {ar} from "date-fns/locale";
import axios from "axios";
// import {CourseProps} from "../../pages/admin/AdminCoursePage";
// import {LessonProps} from "../form/CourseRegistrationForm";
import {CourseProps} from "../../typing/course/course";
import {LessonProps} from "../../typing/lesson/lesson";
interface CurriculumProps {
    course: CourseProps
}

const Curriculum: React.FC<CurriculumProps> = ({course}) => {
    const [lessonAllocated, setLessionAllocated] = useState(new Array(course.totalSession).fill(0));
    const [isUpdate, setIsUpdate] = useState(false);
    const [lessons, setLessons] = useState<LessonProps[]>([]);
    const [appendObj, setAppendObj] = useState<LessonProps | null>(null);
    const [settingLesson, setSettingLesson] = useState([]);
    const [onLoading, setOnLoading] = useState(false);
    const [onUpdatingLesson, setOnUpdatingLesson] = useState(false);

    useEffect(() => {
        setOnLoading(true);
        axios.get(`${process.env.REACT_APP_API_URL}api/class-type/allocated-lesson?id=${course.id}`,{
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + localStorage.ocean_education_token,
            }}).then(res => {
            setSettingLesson(res.data);
            console.log(res.data);
            axios.get(`${process.env.REACT_APP_API_URL}api/class-type/lesson?id=${course.id}`,{
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + localStorage.ocean_education_token,
                }}).then(resFull => {
                console.log(resFull.data);
                let fullLesson = resFull.data;
                let lessonData = res.data;
                let remainingLesson = [];
                // @ts-ignore
                const comparedArr = [];

                const lssA = [...lessonAllocated];
                console.log(lssA);
                let idx = 0;
                // @ts-ignore
                lessonData.forEach(lessonSession => {
                    // console.log(lessonSession);
                    // @ts-ignore
                    lessonSession.lessons.forEach((lesson, index) => {
                        if (lesson !== null) {
                            console.log(index);
                            lssA[idx] = lesson.id;
                            comparedArr.push(lesson);
                        }

                    })
                    idx = idx + 1;
                });
                console.log(lssA);
                setLessionAllocated(lssA);

                // @ts-ignore
                remainingLesson = fullLesson.filter(lesson => !comparedArr.some(comparedLesson => comparedLesson.id == lesson.id));
                console.log(remainingLesson);
                setLessons(remainingLesson);
                setOnLoading(false);
            }).catch(err => {
                console.log(err);
            });
        })


    }, []);

    useEffect(() => {
        console.log(lessonAllocated);
    }, [lessonAllocated]);

    useEffect(() => {
        if (appendObj !== null) {
            setLessons([...lessons, appendObj]);
        }
    }, [appendObj]);

    useEffect(() => {
        console.log(settingLesson);
    }, [settingLesson])

    useEffect(() => {

        console.log(lessons);
    }, [lessons])

    const onUpdateClick = () => {
        console.log(lessonAllocated);
        if (isUpdate) {
            setOnUpdatingLesson(true);
            axios.post(`${process.env.REACT_APP_API_URL}api/class-type/assign-lesson?id=${course.id}`, {
                lesson: lessonAllocated
            }, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + localStorage.ocean_education_token,
                }
            }).then(res => {
                console.log(res);
                setOnUpdatingLesson(false);
                window.location.reload();
            }).catch(err => {
                console.log(err);
            })
        }
        setIsUpdate(!isUpdate);
    }

    return <div className="w-full ml-5 mr-5">
        <div className="pl-4 flex items-center justify-start gap-5">
            <div onClick={onUpdateClick} className="py-1.5 px-3 bg-blue-100 w-fit rounded-xl text-xl cursor-pointer hover:bg-gray-100 active:bg-blue-400">
                {isUpdate ? 'Lưu' : 'Chỉnh sửa'}
            </div>
            {onUpdatingLesson ? <div className="flex items-center justify-start gap-5">
                <div>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-10 h-10 my-auto mx-auto animate-spin">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99" />
                    </svg>
                </div>
                <div>
                    <label>Đang cập nhật giáo trình</label>
                </div>
            </div> : null}
        </div>
        <div className="p-4 w-5/6">
            {settingLesson.map((courseItem, index) => <CurriculumItem course={course} actualLessons={courseItem} lessonAllocated={lessonAllocated} setLessionAllocated={setLessionAllocated} setAppendObj={setAppendObj} setLessons={setLessons} lessons={lessons} isUpdate={isUpdate} title={`Buoi ${index + 1}`} idx={index} key={index} />)}
        </div>
        {onLoading ? <div>
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-10 h-10 my-auto mx-auto animate-spin">
                <path strokeLinecap="round" strokeLinejoin="round" d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99" />
            </svg>
        </div> : null}
    </div>
}

export default Curriculum;
