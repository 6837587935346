import React from "react";
import {LessonProps} from "../../typing/lesson/lesson";
import axios from "axios";

interface DeleteConfirmationProps {
    lesson: LessonProps,
    setRequireDelete: React.Dispatch<React.SetStateAction<boolean>>
}

const DeleteConfirmation: React.FC<DeleteConfirmationProps> = ({lesson, setRequireDelete}) => {
    const onDeleteConfirm = () => {
        axios.delete(`${process.env.REACT_APP_API_URL}api/lesson?id=${lesson.id}`,{
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + localStorage.ocean_education_token,
            }
        }).then(res => {
            console.log(res);
            window.location.reload();
        }).catch(err => {
            alert("Bài học đang được dùng trong một số khoá học")
        })
    }

    return <div className="fixed inset-0 w-full flex bg-transparent items-center justify-center opacity-100 ">
        <div className="w-1/2 h-fit overflow-auto bg-gray-50 border-2 border-gray-400 p-2 rounded-xl flex flex-col gap-4 relative pb-2 mt-10 mb-10 ">
            <div className="w-full text-center text-3xl font-bold">
                <label>Xác nhận xoá bài học</label>
            </div>
            <div className="w-full text-center text-lg">
                <label>Bạn có muốn xoá: {lesson.name}</label>
            </div>
            <div className="w-full py-2 flex items-center justify-between">
                <button onClick={onDeleteConfirm} className="px-4 py-2 bg-red-400 rounded-xl hover:bg-gray-100 active:bg-red-600">Xác nhận</button>
                <button onClick={() => {
                    setRequireDelete(false);
                }} className="px-4 py-2 bg-blue-200 rounded-xl hover:bg-gray-100 active:bg-blue-600">Thoát</button>
            </div>
        </div>

    </div>
}

export default DeleteConfirmation;
