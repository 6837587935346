import React, {useContext, useEffect, useState} from "react";
import {SystemContext, SystemContextProps} from "../../App";
import axios from "axios";
import TeacherRegistrationForm from "../../components/form/TeacherRegistrationForm";
// import {LoginInfoProps, RowBuilderProps, TeacherInfoProps} from "../admin/PeoplePage";
import {LoginInfoProps} from "../../typing/auth/auth";
import {TeacherInfoProps, TeacherRowBuilderProps} from "../../typing/teacher/teacher";
import {generateRandomString} from "../../components/utils/utils";


const CSPeoplePage = () => {
    const {isPhone, isLogin} = useContext(SystemContext) as SystemContextProps;
    const [isAddPeople, setIsAddPeople] = useState(false);
    const [onPeopleLoading, setOnPeopleLoading] = useState(true);
    const [selectedPage, setSelectedPage] = useState(1);
    const [totalPage, setTotalPage] = useState(1);
    const [info, setInfo] = useState<TeacherInfoProps>({
        "id": 0,
        "name": "",
        "address": "",
        "email": "",
        "phone": "",
        "role": "TEACHER",
        "gender": "",
        "dateOfBirth": "",
        "workingType": "",
        "enterprise": "Ocean Stem",
        "curriculum": "",
        "csName": "",
        "type": 0, // teacher as default
        "password": generateRandomString(12)
    });


    const [isUpdate, setIsUpdate] = useState(false);
    const [displayStudents, setDisplayStudent] = useState<TeacherInfoProps[]>([]);
    const [student, setStudents] = useState([]);
    const [loginInfo, setLoginInfo] = useState<LoginInfoProps>({
        email: "",
        password: ''
    });
    const [isLoginInfoShow, setIsLoginInfoShow] = useState(false);
    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_URL}api/student/cs`, {
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + localStorage.ocean_education_token,
            }
        }).then(res => {
            setTotalPage(Math.floor(res.data.length / 10) + 1)
            setStudents(res.data);
            setOnPeopleLoading(false);
        }).catch(err => {
            console.log(err);
        })


    }, []);

    const filterPage = (students: TeacherInfoProps[]) => {
        const tempArr: TeacherInfoProps[] = []
        for (let i = (selectedPage - 1)*10; i < (selectedPage - 1)*10 + 10; i++) {
            if (i < students.length) {
                tempArr.push(students[i]);
            }
        }
        setDisplayStudent(tempArr);
        console.log(tempArr);
    }

    const addHandle = () => {
        setIsAddPeople(!isAddPeople);
        setInfo({
            "id": 0,
            "name": "",
            "address": "",
            "email": "",
            "phone": "",
            "role": "TEACHER",
            "gender": "",
            "dateOfBirth": "",
            "workingType": "",
            "enterprise": "Ocean Stem",
            "type": 0, // teacher as default
            "curriculum": "",
            "csName": "",
            "password": generateRandomString(12)
        })
        setLoginInfo({
            email: "",
            password: ''
        })
        setIsLoginInfoShow(false);
    }

    const RowBuilder: React.FC<TeacherRowBuilderProps> = ({teacher, idx}) => {
        const onRowClickHandle = () => {
            setIsUpdate(true)
            setIsAddPeople(true)
            setInfo({
                "id": teacher.id,
                "name": teacher.name,
                "address": teacher.address,
                "email": teacher.email,
                "phone": teacher.phone,
                "role": teacher.role,
                "gender": teacher.gender,
                "dateOfBirth": teacher.dateOfBirth,
                "workingType": teacher.workingType,
                "enterprise": "Ocean Stem",
                "type": teacher.type, // teacher as default
                "curriculum": teacher.curriculum,
                "csName": "",
                "password": generateRandomString(12)
            });
        }

        return <tr className="border-b transition duration-300 ease-in-out hover:bg-neutral-100 dark:border-neutral-500 dark:hover:bg-neutral-600">
            <td className="whitespace-nowrap px-6 py-4 font-medium">{idx + 1}</td>
            <td className="whitespace-nowrap px-6 py-4">{teacher.name}</td>
            <td className="whitespace-nowrap px-6 py-4">{teacher.phone}</td>
            <td className="whitespace-nowrap px-6 py-4">{teacher.email}</td>
            <td className="whitespace-nowrap px-6 py-4">{teacher.gender}</td>
            <td className="whitespace-nowrap px-6 py-4">{teacher.dateOfBirth}</td>
            <td className="whitespace-nowrap px-6 py-4"><div onClick={onRowClickHandle} className="cursor-pointer bg-orange-200 w-fit px-3 py-1 rounded-xl hover:bg-orange-400 active:bg-orange-500">
                Cập Nhật
            </div></td>
        </tr>
    }


    return <div className={`pl-5 pt-10 pr-5 w-5/6 ${!isPhone ? 'fixed left-28' : ''}  overflow-auto h-screen`}>
        <div className={`${isAddPeople ? 'opacity-50' : 'opacity-100'}`}>
            <div className="flex items-center justify-between">
                <div className="flex">

                </div>
                <div onClick={addHandle} className="bg-purple-300 p-1.5 rounded-xl hover:bg-purple-100 active:bg-purple-500">
                    <div >
                        <label className="px-5 py-2">Thêm Học Sinh</label>
                    </div>
                </div>
            </div>
            <div>
                <div className="flex flex-col">
                    <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
                        <div className="inline-block min-w-full py-2 sm:px-6 lg:px-8">
                            <div className="overflow-hidden">
                                <table className="min-w-full text-left text-sm font-light">
                                    <thead className="border-b font-medium dark:border-neutral-500">
                                    <tr>
                                        <th scope="col" className="px-6 py-4">#</th>
                                        <th scope="col" className="px-6 py-4">Tên</th>
                                        <th scope="col" className="px-6 py-4">Số Điện Thoại</th>
                                        <th scope="col" className="px-6 py-4">Email</th>
                                        <th scope="col" className="px-6 py-4">Giới tính</th>
                                        <th scope="col" className="px-6 py-4">Ngày Tháng Năm Sinh</th>
                                        <th scope="col" className="px-6 py-4">Chỉnh sửa </th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {student.map((student, index) => <RowBuilder teacher={student} idx={index} key={index} />)}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        {onPeopleLoading ? <div>
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-10 h-10 my-auto mx-auto animate-spin">
                <path strokeLinecap="round" strokeLinejoin="round" d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99" />
            </svg>
        </div> : null}
        {isAddPeople ? <TeacherRegistrationForm setIsLoginInfoShow={setIsLoginInfoShow} setLoginInfo={setLoginInfo} setInfo={setInfo} info={info} isAddPeople={isAddPeople} setIsAddPeople={setIsAddPeople} isTeacher={false} setIsUpdate={setIsUpdate} isUpdate={isUpdate}/> : null}
        {isLoginInfoShow ? <div className="fixed inset-0 flex items-center justify-center">
            <div className="w-1/2 h-2/5 bg-gray-50 border-2 border-gray-400 p-2 rounded-xl flex flex-col gap-4 relative">
                <div className="text-xl font-bold p-4 text-center">
                    <label>Tài Khoản Của Giáo Viên</label>
                </div>
                <div className="text-xl font-bold p-4 text-left">
                    <label>Email Đăng Nhập: {loginInfo.email} </label>
                </div>
                <div className="text-xl font-bold p-4 text-left">
                    <label>Mật khẩu: {loginInfo.password}</label>
                </div>
                <div onClick={() => {
                    setIsLoginInfoShow(false);
                    window.location.reload();
                }} className="flex justify-center">
                    <div className="bg-blue-200 py-1.5 px-5 rounded-xl hover:bg-gray-200 active:bg-blue-500 cursor-pointer">
                        OK
                    </div>
                </div>
            </div>
        </div> : null}


    </div>
}

export default CSPeoplePage;
