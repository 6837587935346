import React, {useEffect, useRef, useState} from "react";
import { Document, Page,pdfjs } from 'react-pdf';
import {useLocation} from "react-router-dom";
import axios from "axios";
import {base64ToFile, downloadFile} from "../../components/utils/utils";
import {Viewer, Worker} from "@react-pdf-viewer/core";
import {defaultLayoutPlugin} from "@react-pdf-viewer/default-layout";
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css'

const Lecture = () => {
    const location = useLocation();
    const [lesson, setLesson] = useState(location.state.lesson);
    const [course, setCourse] = useState(location.state.course);
    const [file, setFile] = useState<File | null>(null);
    const [base64Str, setBase64Str] = useState('');
    const [isWaitingDownload, setIsWaitingDownload] = useState(false);
    const [test, setTest] = useState(null);
    const [isAbleDownload, setIsAbleDownload] = useState(false);
    const [pdfView, setPdfView] = useState('');
    const newPlugin = defaultLayoutPlugin();

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_URL}api/lesson/get-file?name=${lesson.id}.pdf`, {
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + localStorage.ocean_education_token,
            }
        }).then(res => {
            setBase64Str(res.data);
        })

        axios.get(`${process.env.REACT_APP_API_URL}api/lesson/get-file?name=${lesson.id}.${lesson.fileId}`, {
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + localStorage.ocean_education_token,
            }
        }).then(res => {
            // @ts-ignore
            setFile(base64ToFile(res.data, lesson.name, lesson.fileId));
            console.log("done ");
        })
    }, [])

    useEffect(() => {
        let idx = 0;
        const currentDate = new Date();
        while (idx < course.totalSession) {
            let startDate = new Date(parseInt(course.startDate.split("-")[2]), parseInt(course.startDate.split("-")[1]) - 1, parseInt(course.startDate.split("-")[0]));
            startDate.setDate(startDate.getDate() + idx * 7)
            if (areDatesEqual(currentDate, startDate) && (idx == location.state.idx)) {
                console.log('match')
                setIsAbleDownload(true);
            }
            idx++;
        }
    }, [])

    function areDatesEqual(date1: Date, date2: Date) {
        return (
            date1.getFullYear() === date2.getFullYear() &&
            date1.getMonth() === date2.getMonth() &&
            date1.getDate() === date2.getDate()
        );
    }


    useEffect(() => {
        if (isWaitingDownload) {
            downloadFile(file, `${lesson.name}.${lesson.fileId}`);
            setIsWaitingDownload(false);
        }
    }, [file])

    const onDownloadClick = () => {
        if (file === null) {
            setIsWaitingDownload(true);
        }else {
            downloadFile(file, `${lesson.name}.${lesson.fileId}`);
        }

    }

    const iframeRef = useRef<HTMLIFrameElement>(null);

    useEffect(() => {
        const disableRightClick = (e: React.MouseEvent<HTMLDivElement>) => {
            e.preventDefault();
        };

        const iframe = iframeRef.current;
        if (iframe && iframe.contentWindow && iframe.contentWindow.document) {
            // @ts-ignore
            iframe.contentWindow.document.addEventListener('contextmenu', disableRightClick);
        }

        return () => {
            // Clean up the event listener when the component unmounts
            if (iframe && iframe.contentWindow && iframe.contentWindow.document) {
                // @ts-ignore
                iframe.contentWindow.document.removeEventListener('contextmenu', disableRightClick);
            }
        };
    }, []);

    const base64toBlob = (data: string) => {
        // Cut the prefix `data:application/pdf;base64` from the raw base 64
        const base64String = "YOUR_BASE64_STRING_HERE";
        const binaryString = atob(data);
        const byteArray = new Uint8Array(binaryString.length);
        for (let i = 0; i < binaryString.length; i++) {
            byteArray[i] = binaryString.charCodeAt(i);
        }

        const blob = new Blob([byteArray], { type: 'application/pdf' });
        const blobUrl = URL.createObjectURL(blob);

        return blobUrl;
    };

    return <div className="pl-5 pt-10 pr-5 w-full fixed left-28 overflow-auto h-screen overflow-auto  ">
        <div className=" w-5/6 h-full flex flex-col gap-3">
            <label className="text-3xl font-bold">BÀI HỌC</label>
            {(isAbleDownload) ? <div className="flex gap-2.5">
                <label className="text-xl">{`${lesson.name}.${lesson.fileId}`}</label>
                <div onClick={onDownloadClick}>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.0} stroke="currentColor" className="w-6 h-6 active:w-7 active:h-7">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M3 16.5v2.25A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75V16.5M16.5 12L12 16.5m0 0L7.5 12m4.5 4.5V3" />
                    </svg>
                </div>
            </div>: null}
            <div className="w-full h-screen">
                {/*<object type="application/pdf" className="w-full h-full" data={`data:application/pdf;base64,${base64Str}`} />*/}
                {/*<img src={pdfView} alt={"Test"} />*/}
                <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
                    {base64Str.length !== 0 ? <Viewer fileUrl={`data:application/pdf;base64,${base64Str}`} plugins={[newPlugin]}/> : null}
                </Worker>

                {(base64Str.length === 0) ? <div className="px-4 py-2.5 bg-indigo-500 w-fit mt-5 flex gap-5 items-center rounded-xl">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 100 100"
                        width="30"
                        height="30"
                        fill="#000000"
                        className="animate-spin"
                    >
                        <circle
                            cx="50"
                            cy="50"
                            r="40"
                            stroke="#A084E8"
                            strokeWidth="15"
                            fill="transparent"
                        />
                        <circle
                            cx="50"
                            cy="50"
                            r="40"
                            stroke="#8BE8E5"
                            strokeWidth="15"
                            fill="transparent"
                            strokeDasharray="180"
                            strokeDashoffset="0"
                        >

                        </circle>
                    </svg>
                    <label className="text-white text-lg">Đang tải file</label>
                </div> : null}
                {/*<embed className="w-full h-full" src={"https://docs.google.com/presentation/d/1gXuaBFpwRvo4B0_P6Z7odJ6O9ujR0gir/edit?usp=drive_link&ouid=108067485919831535902&rtpof=true&sd=true"} />*/}

            </div>
        </div>

    </div>

}

export default Lecture;
