import React, {useEffect, useState} from 'react';
import {Link, Route, Routes} from "react-router-dom";
import LoginPage from "./pages/auth/LoginPage";
import PeoplePage from "./pages/admin/PeoplePage";
import EnterpriseNavBar from "./components/NavBarDir/EnterpriseNavBar";
import AdminCoursePage from "./pages/admin/AdminCoursePage";
import AdminLessonPage from "./pages/admin/AdminLessonPage";
import CourseManagementCalender from "./pages/admin/CourseManagementCalender";
import AnnouncementPage from "./pages/admin/AnnouncementPage";
import AccountPage from "./pages/auth/AccountPage";
import ChangePasswordPage from "./pages/auth/ChangePasswordPage";
import NavBar from "./components/NavBarDir/NavBar";
import TeacherCoursesPage from "./pages/teacher/TeacherCoursesPage";
import TeachingCalendar from "./pages/teacher/TeachingCalendar";
import {RequireAuth} from "react-auth-kit";
import CoursePage from "./pages/teacher/CoursePage";
import Lecture from "./pages/teacher/Lecture";
import AdminNavBar from "./components/NavBarDir/AdminNavBar";
import CSPeoplePage from "./pages/cs/CSPeoplePage";
import AdminCSPage from "./pages/admin/AdminCSPage";
import AdminTeacherPage from "./pages/admin/AdminTeacherPage";

export interface SystemContextProps {
    isPhone: boolean,
    setIsLogin: React.Dispatch<React.SetStateAction<boolean>>,
    isLogin: boolean
}

export const SystemContext = React.createContext<SystemContextProps | null>(null);

function App() {
    const [isPhone, setIsPhone] = useState(false);
    const [burgerClick, setBurgerClick] = useState(false);
    const [isLogin, setIsLogin] = useState(false);

    useEffect(() => {
        if (localStorage.ocean_software_role == 1) {
            console.log("This is admin")
        }

        if (window.innerWidth < 900) {
            setIsPhone(true);
        }else {
            setIsPhone(false);
        }
    }, [])

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth < 900) {
                setIsPhone(true);
            }else {
                setIsPhone(false);
            }
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [])

    const onBurgerClick = () => {
        setBurgerClick(!burgerClick);
    }

    return (
        <div>
            <SystemContext.Provider value={{isPhone, setIsLogin, isLogin}}>
                {/*<AdminNavBar/>*/}
                {localStorage.ocean_software_role === undefined ? null : (localStorage.ocean_software_role == 0) ? <NavBar/> : (localStorage.ocean_software_role == 1) ? <AdminNavBar/> : <EnterpriseNavBar/>}
                <Routes>
                    <Route path={"/"} element={<LoginPage setIsLogin={setIsLogin}/>} />
                    <Route path={"/people"} element={<RequireAuth loginPath="/login"><CSPeoplePage/></RequireAuth>} />
                    <Route path={"/cs-account"} element={<RequireAuth loginPath="/login"><AdminCSPage/></RequireAuth>} />
                    <Route path={"/admin/people"} element={<RequireAuth loginPath="/login"><PeoplePage/></RequireAuth>} />
                    <Route path={"/admin/course-page"} element={<RequireAuth loginPath="/login"><AdminCoursePage/></RequireAuth>} />
                    <Route path={"/admin/lesson"} element={<RequireAuth loginPath="/login"><AdminLessonPage/></RequireAuth>} />
                    <Route path={"/admin/teacher"} element={<RequireAuth loginPath="/login"><AdminTeacherPage/></RequireAuth>} />
                    <Route path={"/admin/calendar-management"} element={<RequireAuth loginPath="/login"><CourseManagementCalender/></RequireAuth>} />
                    <Route path={"/admin/announcement"} element={<RequireAuth loginPath="/login"><AnnouncementPage/></RequireAuth>} />
                    <Route path={"/teacher/course"} element={<RequireAuth loginPath="/login"><TeacherCoursesPage/></RequireAuth>} />
                    <Route path={"/teacher/calendar"} element={<RequireAuth loginPath="/login"><TeachingCalendar/></RequireAuth>} />
                    <Route path={"/teacher/lecture"} element={<RequireAuth loginPath="/login"><Lecture/></RequireAuth>} />
                    <Route path={"/account"} element={<RequireAuth loginPath="/login"><AccountPage/></RequireAuth>} />
                    <Route path={"/course"} element={<RequireAuth loginPath="/login"><CoursePage/></RequireAuth>} />
                    <Route path={"/change-password"} element={<ChangePasswordPage/>}/>

                </Routes>
            </SystemContext.Provider>

        </div>
    );
}

export default App;
